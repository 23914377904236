html, body {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    max-width: 2000px;
    margin: auto;
}

#root {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.quill {
    height: 100%
}

.ql-container.ql-snow {
    border: none !important;
    height: 100% !important;
    max-height: 624px !important;
    overflow: scroll !important;
}

.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #CBD5E0 !important;
}

.ql-editor {
    height: 100% !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 100 !important;
    font-size: 17px !important;
    line-height: 23px !important;
    padding-bottom: 62px !important;
}

.ql-container.ql-snow {
    min-height: 100% !important
}

.ql-container {
    height: 100% !important;
}

.ql-editor p {
    margin-bottom: 8px !important;
}

.ql-editor h1 {
    margin-bottom: 8px !important; 
    font-weight: 400 !important;
    line-height: 42px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.ql-editor h2 {
    margin-top: 20px !important;
    margin-bottom: 8px !important;
    font-weight: 400 !important;
    line-height: 42px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.ql-editor h3 {
    margin-bottom: 8px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.ql-editor iframe {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

#veoWriterHTMLContainer {
    background-color: #313138;
    padding-top: 40px;
    padding-bottom: 80px;
}

#veoWriterHTMLContainer {
    color: rgb(255, 255, 255);
    width: 100%;
    min-width: 600px;
}

#veoWriterHTMLContainer a {
    text-decoration: underline;
} 

#veoWriterHTMLContainer a:Hover {
    text-decoration: underline;
    color: rgb(189, 189, 189);
} 

#veoWriterHTMLContainer h1 {
    margin-top: 1rem;
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 1.8rem;
    line-height: 1.2;
    color: rgb(255, 255, 255);
} 

#veoWriterHTMLContainer h2 {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.3333333;
    margin-bottom: -0.6rem;
    margin-top: 2rem;
    color: rgb(255, 255, 255);
} 

#veoWriterHTMLContainer h3 {
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.6;
    margin-bottom: -0.6rem;
    margin-top: 2rem;
    color: rgb(255, 255, 255);
  } 

#veoWriterHTMLContainer p {
    color: rgb(209, 213, 219);
    margin-bottom: -0.4rem;
    line-height: 1.6;
    font-size: 1.05rem;
} 

#veoWriterHTMLContainer table {
    border: 1px solid #dededf;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: center;
    margin-top: 1.2rem;
    font-size: 1.05rem;

} 

#veoWriterHTMLContainer table th {
    border: 1px solid #dededf;
    background-color: #eceff1;
    color: #000000;
    padding: 8px;
    font-size: 1.05rem;

}

#veoWriterHTMLContainer table td {
    border: 1px solid #dededf;
    background-color: #fffff0;
    color: #000000;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 1.05rem;

}

#veoWriterHTMLContainer ul {
    padding-left: 1.5rem;
    margin-top: -1rem;
    font-size: 1.05rem;
    margin-bottom: -1rem;
}   

#veoWriterHTMLContainer ol {
    padding-left: 1.5rem;
    margin-top: -1rem;
    font-size: 1.05rem;

} 

#veoWriterHTMLContainer li {
    line-height: 1.2rem; /* Set a reasonable line-height to provide space between lines */
    margin-bottom: -0.8rem; /* Adds some space below each list item */
    color: rgb(209, 213, 219);
    font-size: 1.05rem;

}

#veoWriterHTMLAdapterContainer li strong {
    margin-right: 0.5rem; /* Add space after "Munich:" */
    flex-shrink: 0; /* Prevent "Munich:" from shrinking */
    color: rgb(255, 255, 255);
    font-size: 1.05rem;
}

#veoWriterHTMLContainer strong {
    color: rgb(255, 255, 255);
    font-size: 1.05rem;

}

#veoWriterHTMLContainer th strong {
    color: #000000;
    font-size: 1.05rem;

}

#veoWriterHTMLContainer td strong {
    color: #000000;
    font-size: 1.05rem;

}